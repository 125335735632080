import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import JoinUs from '../sections/joinUs'
import GrowDiamond from '../sections/growADiamond'
import Baby from '../sections/baby'
import { PageHead } from '../common'


export default () => (
  <Layout>
    <SEO
      title="Baby Jewelry"
      description="You’re never too young to be adorned with jewels. At Underwood’s in Jacksonville and Ponte Vedra, we have the perfect jewelry for your child.  You can also check out our Grow A Diamond program!" />
    <PageHead>
      <h1>BABY JEWELRY</h1>
      <p>Underwood's has something for the baby that has everything. From baby earrings to sterling silver bangle bracelets you can gift your tiny loved one with a keepsake that will last.</p>
    </PageHead>
    <Baby />
    <GrowDiamond />
    <JoinUs />
  </Layout>
)
